<template>
  <div>
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item active>行銷應用中心</b-breadcrumb-item>
          <b-breadcrumb-item :to="{ name: 'RewardCardList' }">集點卡管理</b-breadcrumb-item>
          <b-breadcrumb-item :to="{ name: 'RewardMissionList', params: { reward_card_id: rewardCardId} }">集點任務</b-breadcrumb-item>
          <b-breadcrumb-item active>{{ isReadOnly ? "檢視集點任務" : isEditing ? '編輯集點任務' : '新增集點任務' }}</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading">
        <h4 class="mb-3 font-weight-bold">{{ pageTitle }}</h4>
        <div class="mb-2">
          <validation-error-alert
            v-if="validationErrors"
            :errors="validationErrors"
          ></validation-error-alert>
          <!-- 任務名稱 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="任務名稱"
          >
            <b-form-input
              type="text"
              :state="deepGet(v$, 'form.title.$error') ? false: null"
              v-model="form.title"
              :readonly="isReadOnly"
            ></b-form-input>
            <b-form-invalid-feedback :state="!deepGet(v$, 'form.title.$error')">
              此欄位為必填
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- 任務名稱 End -->
          <!-- 使用期間 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="使用期間"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-radio :disabled="isReadOnly" v-model="form.period_type" value="by_card">
                與集點卡相同
              </b-form-radio>
            </div>
            <div class="d-flex align-items-center mb-2">
              <b-form-radio :disabled="isReadOnly" v-model="form.period_type" value="custom" class="mr-2">
                自訂期限
              </b-form-radio>
              <div class="d-flex align-items-center">
                <datepicker
                  placeholder="選擇日期"
                  v-model="form.start_at"
                  bootstrap-styling
                  :language="zh"
                  format="yyyy-MM-dd"
                  :disabled="isReadOnly || form.period_type !== 'custom'"
                  :clear-button="!isReadOnly"
                  :input-class="deepGet(v$, 'form.start_at.$error') ? 'is-invalid' : ''"
                ></datepicker>
                <span class="mx-2">~</span>
                <datepicker
                  placeholder="選擇日期"
                  v-model="form.end_at"
                  bootstrap-styling
                  :language="zh"
                  format="yyyy-MM-dd"
                  :disabled="isReadOnly || form.period_type !== 'custom'"
                  :clear-button="!isReadOnly"
                  :input-class="deepGet(v$, 'form.end_at.$error') ? 'is-invalid' : ''"
                ></datepicker>
              </div>
            </div>
            <b-form-invalid-feedback
              :state="!deepGet(v$, 'form.start_at.beforeEndAt.$invalid')"
            >
              結束時間必須大於起始時間
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- 使用期間 End -->
          <!-- 可累點數 Start -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="可累點數"
          >
            <b-form-input
              type="text"
              :state="deepGet(v$, 'form.earn_point.$error') ? false: null"
              v-model="form.earn_point"
              :readonly="isReadOnly"
              style="width: 200px"
            ></b-form-input>
            <b-form-invalid-feedback :state="!deepGet(v$, 'form.earn_point.$error')">
              此欄位為必填
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="取得點數限制"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-checkbox v-model="form.config.enable_total_limit" :disabled="isReadOnly" id="limit1"></b-form-checkbox>
              <label class="mb-0" for="limit1">
                每人上限
                <b-form-input
                  :readonly="isReadOnly || form.config.enable_total_limit !== true"
                  v-model="form.config.total_limit"
                  :type="'number'"
                  class="mx-2"
                  style="width: 100px"
                ></b-form-input>
                次
              </label>
            </div>
            <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.total_limit.$error')" class="mb-2">
              此欄位為必填且必須為數字
            </b-form-invalid-feedback>
            <div class="d-flex align-items-center mb-2">
              <b-form-checkbox v-model="form.config.enable_daily_limit" :disabled="isReadOnly" id="limit2"></b-form-checkbox>
              <label class="mb-0" for="limit2">
                單日每人上限
                <b-form-input
                  :readonly="isReadOnly || form.config.enable_daily_limit !== true"
                  v-model="form.config.daily_limit"
                  :type="'number'"
                  class="mx-2"
                  style="width: 100px"
                ></b-form-input>
                次
              </label>
            </div>
            <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.daily_limit.$error')" class="mb-2">
              此欄位為必填且必須為數字
            </b-form-invalid-feedback>
            <div class="d-flex align-items-center mb-2">
              <b-form-checkbox v-model="form.config.enable_period_limit" :disabled="isReadOnly" id="limit3"></b-form-checkbox>
              <label class="mb-0" for="limit3">
                於
                <b-form-input
                  :readonly="isReadOnly || form.config.enable_period_limit !== true"
                  v-model="form.config.period_limit"
                  :type="'number'"
                  class="mx-2"
                  style="width: 100px"
                ></b-form-input>
                小時內不得重複
              </label>
            </div>
            <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.period_limit.$error')" class="mb-2">
              此欄位為必填且必須為數字
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- 取得點數限制 End -->
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="核銷方式"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-radio :disabled="isReadOnly" v-model="form.config.confirm_type" value="none">
                不用核銷
              </b-form-radio>
            </div>
            <div class="d-flex align-items-center mb-2">
              <b-form-radio :disabled="isReadOnly" v-model="form.config.confirm_type" value="input">
                輸入店號核銷
              </b-form-radio>
            </div>
            <div class="d-flex align-items-center mb-2">
              <b-form-radio :disabled="isReadOnly" v-model="form.config.confirm_type" value="select">
                選擇分店核銷
              </b-form-radio>
              <b-form-input
                :readonly="isReadOnly"
                v-model="form.config.branch_provider"
                class="mx-2"
                style="width: 20rem"
                placeholder="分店下拉選單來源"
              ></b-form-input>
            </div>
          </b-form-group>
        </div>
        <!-- 欄位部分 End -->
        <!-- 表單底部 Start -->
        <div class="d-flex justify-content-center">
          <b-button
            class="mr-3"
            @click="cancel"
            variant="outline-danger"
          >
            返回
          </b-button>
          <b-button
            @click="submit"
            variant="success"
            v-if="false == isReadOnly"
          >
            儲存
          </b-button>
        </div>
        <!-- 表單底部 End -->
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { isBefore } from "date-fns";
import moment from "moment";
import { mapGetters, mapState } from 'vuex';
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import rewardCardApi from "@/apis/reward-card";
import rewardMissionApi from "@/apis/reward-mission";
import deepGet from "@/utils/deepGet";

export default {
  components: {
    Datepicker,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        title: { required },
        period_type: { required },
        earn_point: { required },
        // limit_count: { required },
        start_at: {
          beforeEndAt: () => {
            if (!this.form.end_at || !this.form.start_at || this.form.period_type !== "custom") {
              return true
            }

            return isBefore(new Date(this.form.start_at), new Date(this.form.end_at));
          },
        },
        config: {
          total_limit: {
            required: requiredIf(() => this.form.config.enable_total_limit),
          },
          daily_limit: {
            required: requiredIf(() => this.form.config.enable_daily_limit),
          },
          period_limit: {
            required: requiredIf(() => this.form.config.enable_period_limit),
          },
        }
      },
    };
  },
  data() {
    return {
      zh,
      showLoading: true,
      rewardCard: null,
      form: {
        title: null,
        period_type: 'by_card',
        start_at: null,
        end_at: null,
        earn_point: null,
        // limit_count: null,
        config: {
          enable_total_limit: null,   // 是否啟用每人上限
          enable_daily_limit: null,   // 是否啟用單日每人上限
          enable_period_limit: null,  // 是否啟用於幾小時內不得重複
          total_limit: null,  // 每人上限
          daily_limit: null,  // 單日每人上限
          period_limit: null, // 於幾小時內不得重複
          confirm_type: 'none', // 核銷方式
        },
      },
      branchConfirmTypeOptions: [
        { value: 'input', text: "輸入店號核銷" },
        { value: 'select', text: "選擇分店核銷" },
        { value: null, text: "無分店核銷" },
      ],
      validationErrors: null,
    };
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
    ...mapState("general", ['defaultMerchantType']),
    rewardCardId() {
      return this.$route.params.reward_card_id;
    },
    id() {
      return this.$route.params.reward_mission_id;
    },
    isEditing() {
      return !!this.id;
    },
    isReadOnly() {
      return this.$route.name == "RewardMissionView";
    },
    pageTitle() {
      const verb = this.isReadOnly ? "檢視" : this.isEditing ? "編輯" : "新增";
      return this.rewardCard ? `【${this.rewardCard.title}】${verb}集點任務` : `${verb}集點任務`;
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.rewardCardId ? this.fetchRewardCard() : undefined,
        this.id ? this.fetchRewardMission() : undefined,
      ]);
    } catch (e) {
      console.error(e);
      this.$swal("錯誤", "讀取資料錯誤", "error");
      this.$router.replace({name: "RewardMissionList", query: this.routeQuery});
    }

    this.showLoading = false;
  },
  methods: {
    deepGet,
    cancel() {
      this.$router.push({ name: 'RewardMissionList', query: this.routeQuery });
    },
    async fetchRewardCard() {
      const { data } = await rewardCardApi.get(this.rewardCardId);

      this.rewardCard = data.data
    },
    async fetchRewardMission() {
      const { data } = await rewardMissionApi.get(this.rewardCardId, this.id);

      Object.keys(this.form).forEach(key => {
        this.$set(this.form, key, deepGet(data, `data[${key}]`, this.form[key]));
      })
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;

      try {
        this.showLoading = true;

        if (this.isEditing) {
          const response = await rewardMissionApi.update(this.rewardCardId, {
            id: this.id,
            ...this.form,
            start_at: this.form.start_at ? moment(this.form.start_at).format("YYYY-MM-DD 00:00:00") : null,
            end_at: this.form.end_at ? moment(this.form.end_at).format("YYYY-MM-DD 23:59:59") : null,
          });

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({name: "RewardMissionList", query: this.routeQuery});
          }
        } else {
          const response = await rewardMissionApi.store(this.rewardCardId, {
            ...this.form,
            start_at: this.form.start_at ? moment(this.form.start_at).format("YYYY-MM-DD 00:00:00") : null,
            end_at: this.form.end_at ? moment(this.form.end_at).format("YYYY-MM-DD 23:59:59") : null,
          });

          if (response.status && response.status >= 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({name: "RewardMissionList", query: this.routeQuery});
          }
        }
      } catch (error) {
        this.showLoading = false;

        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
          return
        }

        this.$swal.fire({title: this.isEditing ? "更新失敗" : "新增失敗", type: "error",});
      }
    },
  },
};
</script>
